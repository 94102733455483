import React from "react"

import Layout from "../components/layout"
import Image from "../components/image"
import Seo from "../components/seo"

const IndexPage = () => (
  <Layout>
    <Seo title="Join the Story, Share Your Stoke" />
    <div className="home-upper">
      <div className="eos-logo">
        <Image />
      </div>
    </div>

    <div className="home-lower">
      <h2>Website on Hiatus Indefinitely</h2>
      <p>Our <a href="https://www.facebook.com/endofsessionproductions" target="_blank" rel="noopener noreferrer">Facebook Page</a> will continue to share the stoke.
      Please <a href="https://www.facebook.com/endofsessionproductions" target="_blank" rel="noopener noreferrer">Like us</a> or join the Story.</p>

      <p>Subscribe to our <a href="https://vimeo.com/endofsession" target="_blank" rel="noopener noreferrer">Vimeo Channel</a> to see all of our 
      Kiteboarding videos.</p>

      <p>Also check out <a href="https://kiteboardminnesota.com/" target="_blank" rel="noopener noreferrer">Kiteboard Minnesota</a> for locations to ride, how to 
        started,  and info on kiting.</p>

      <p>Keep the stoke alive.</p>

      <p><strong>End of Session.</strong></p>
    </div>
  </Layout>
)

export default IndexPage
